import { fas } from '@fortawesome/free-solid-svg-icons';
import { Box, Divider, Fab, Grid, InputLabel, Tooltip, Typography } from "@mui/material";
import equipmentApi from 'api/equipment';
import requestApi from "api/request";
import { FontAwesomeIcon, faMoneyBill, faSprayCanSparkles } from "assets/fontAwesome/FontAwesomeIcons";
import {
    EditIcon,
    NotesIcon,
    PlaceIcon,
    SendIcon,
    ShoppingCartIcon
} from 'assets/mui/MuiIcons';
import CustomIconButton from "components/Button/CustomIconButton";
import CustomOutletDetailsCard from 'components/Card/outlet/CustomOutletDetailsCard';
import CustomDialogBasedOnScreenChildren from "components/Dialog/CustomDialogBasedOnScreenChildren";
import { getDialogTypeAnimation } from "components/Dialog/animation/dialogTypeAnimation";
import CustomGrid from 'components/Grid/CustomGrid';
import CustomTopHeaderChildren from 'components/Header/CustomTopHeaderChildren';
import RequestLoadingSkeleton from 'components/Loading/RequestLoadingSkeleton';
import CheckoutEquipmentPopover from 'components/Popover/CheckoutEquipmentPopover';
import CustomOutletSection from 'components/Section/CustomOutletSection';
import CustomTextArea from "components/Text/CustomTextArea";
import CustomTypography from 'components/Typography/CustomTypography';
import { getDefaultRequestObject, getTranslationDefaultObject } from "constants/defaultObjects";
import { getRequestButton, getRequestButtonIcon } from "constants/fieldProperties";
import { requestTextFieldLimit } from "constants/textFieldLimit";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { createSearchParams, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { mainTag, orderHeaderTextStyle, orderIconStyle, orderTextStyle, requestButtonFontAwesomeIcon, requestButtonIcon, requestFabButtonStyle, requestTextStyle, requestTextareaBoxStyle, requestTitleStyle } from 'themes/defaultThemes';
import { formValChangeWithParentElementWithNameAndValue } from "utils/functions";
import { getLanguageFromURL } from "utils/language";
import useDocumentTitle from "utils/useDocumentTitle";

/**
 * The RequestComponent, that presents a customer request component.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function RequestComponent() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation()

    const { outletURL } = useParams();
    const params = new URLSearchParams(location.search)
    const equipmentLabel = params.get("e")

    //change document title
    useDocumentTitle(`Requests`);

    // create a counter to enable the resend code logic after 30sec
    const timerIdRef = useRef(0);
    const [counter, setCounter] = useState(120);

    /**
     * @type {object}
     * @param {object} userAuth the authenticated user infromation
     */
    const [data, setData] = useState({
        userAuth: null,
        request: getDefaultRequestObject(),
        outlet: null,
        organization: null,
    });

    const [isLoaded, setIsLoaded] = useState(false);
    // pop dialog for delete
    const [dialog, setDialog] = useState({
        isOpen: false,
        type: "success",
        message: ""
    });

    const [disableSendButton, setDisableSendButton] = useState(true);
    // login
    const [isOpenLogin, setIsOpenLogin] = useState(false);

    useEffect(() => {
        clearInterval(timerIdRef.current);
        getRequest(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (data.request.type !== "") setDisableSendButton(false);
    }, [data.request.type])

    /**
     * Callback that handles the start of the time interval for the resend code button to be shown on end.
     * @returns nothing
     */
    function startHandler() {
        if (timerIdRef.current) {
            return;
        }
        timerIdRef.current = setInterval(() => setCounter((prevCounter) => (prevCounter === 0) ? stopHandler() : prevCounter - 1), 1000);
    }

    /**
     * Callback that handles the end of the time interval for the resend code button to be shown.
     */
    function stopHandler() {
        clearInterval(timerIdRef.current);
        timerIdRef.current = 0;
        return 0;
    }

    function getRequest(sendButtonISPressed = true) {
        requestApi.fetchNew(outletURL, data.userAuth !== null).then((r) => {
            if (sendButtonISPressed) {
                if (r.data?.code === "PERMISSION") {
                    setIsOpenLogin(true);
                } else {
                    setIsOpenLogin(false);
                }
            }

            setData({
                ...data,
                userAuth: r.data.returnobject.userAuth,
                organization: r.data.returnobject.organization,
                outlet: r.data.returnobject.outlet,
                request: getDefaultRequestObject(outletURL, equipment),
                types: r.data.returnobject.types,
                settings: r.data.returnobject.settings,
                requestExtraCustomButtons: r.data.returnobject.requestExtraCustomButtons
            });
            setIsLoaded(true);
        }).catch((e) => {
            // console.log(e);
        });
    }

    // ========================= EQUIPMENT 
    const [equipmentAnchorEl, setEquipmentAnchorEl] = useState(null);
    const [equipment, setEquipment] = useState(equipmentLabel);
    const [equipmentInvalid, setEquipmentInvalid] = useState(false);

    function updateEquipmentOpenCallback(event) {
        setEquipment(equipment)
        setEquipmentAnchorEl(event.currentTarget);
    };

    function updateEquipmentCloseCallback() {
        setEquipmentAnchorEl(null);
    };

    function updateEquipmentValue(event) {
        setEquipment(event.target.value);
    };

    function updateEquipmentCallback() {
        validateNewEquipmentLabel(equipment);
    };

    const openEquipment = Boolean(equipmentAnchorEl);
    console.log(data.request)
    /**
     * The rest endpoint to validate the new inputed equipment label and return its details (id, label).
     */
    function validateNewEquipmentLabel() {
        equipmentApi.validateExistance(data?.outlet?.id, equipment).then((r) => {
            if (r.data?.code === "SUCCESS" && r.data?.returnobject !== null) {
                // Optionally close the popover
                updateEquipmentCloseCallback();
                setEquipmentInvalid(false);
                updateURLParameter('e', r.data?.returnobject?.label);
            } else if (r.data?.code === "PERMISSION") {
                setIsOpenLogin(true);
            } else {
                setEquipmentInvalid(true);
            }
        }).catch((e) => {
            // console.log(e);
            setEquipmentInvalid(true);
        });
    }

    const [searchParams] = useSearchParams();

    function updateURLParameter(param, value) {
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.set(param, value);
        navigate(`?${newSearchParams.toString()}`);
    };

    // =============================================================================== FUNCTIONS

    function createRequest() {
        if (disableSendButton) {
            setCounter(120);
            startHandler();
            handleDialogState(true, "info", t('request.alreadySendRequestMessage'))
        } else {
            setDisableSendButton(true);
            let request = data.request;
            requestApi.create(request).then((r) => {
                if (r.data?.code === "PERMISSION") {
                    setIsOpenLogin(true);
                } else {
                    formValChangeWithParentElementWithNameAndValue("request", getDefaultRequestObject(outletURL, equipment), data, setData);
                    handleDialogState(true, (r.data.code === "SUCCESS") ? "success" : "info", r.data.message)
                }
            }).catch((e) => {
                handleDialogState(true, "fail", e.message)
            });
        }
    }

    /**
     * Function that handles the delete modal open or close data.
     * @property {boolean} isOpen The state of the dialog open status.
     * @property {string} type The type of the dialog 'success', 'fail' or 'info'.
     */
    function handleDialogState(isOpen, type = "success", message = "") {
        setDialog({
            ...dialog,
            isOpen: isOpen,
            type: type,
            message: message
        });
    }

    // ====================================== OUTLET
    const [outletDialog, setOutletDialog] = useState({
        isOpen: false,
        type: "success",
        message: ""
    });

    // ====================================== splash
    const ss = params.get("ss");
    let outletSplashConfiguration = useSelector(state => state.splash[outletURL] || null);
    const [splashCount, setSplashCount] = useState(0);
    const [showSplash, setShowSplash] = useState(true);

    /**
     * Function that handles the delete modal open or close data.
     * @property {boolean} isOpen The state of the dialog open status.
     * @property {string} type The type of the dialog 'success', 'fail' or 'info'.
     */
    function handleOutletDialogState(isOpen, type = "success", message = "") {
        setOutletDialog({
            ...outletDialog,
            isOpen: isOpen,
            type: type,
            message: message
        });
    }

    useEffect(() => {
        if (ss !== "1") setShowSplash(false);
        getRequest();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (outletSplashConfiguration?.splashConfiguration !== null && ss === "1") {
            const interval = setInterval(() => {
                setSplashCount((prevCount) => prevCount + 1);
            }, 1000); // Increment count every second

            return () => clearInterval(interval);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ss]);


    useEffect(() => {
        if (outletSplashConfiguration?.splashConfiguration !== null && splashCount >= outletSplashConfiguration?.splashConfiguration.durationInSeconds && showSplash) {
            setShowSplash(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [splashCount]);


    // ===============================================================================RENDER

    if (!isLoaded) {
        return <CustomGrid role="main" id="request" sx={mainTag()}>
            <CustomTopHeaderChildren
                key="th"
                isOpen={isOpenLogin}
                handleLoginCheckout={() => getRequest()}
                setIsOpen={setIsOpenLogin}
                pageRequireLoginRegistation={isOpenLogin}
            />
            <RequestLoadingSkeleton />
        </CustomGrid>
    } else
        return (
            <CustomGrid role="main" id="request" sx={mainTag()}>
                <CustomTopHeaderChildren
                    key="th"
                    isOpen={isOpenLogin}
                    handleLoginCheckout={() => getRequest()}
                    setIsOpen={setIsOpenLogin}
                    pageRequireLoginRegistation={isOpenLogin}
                />

                <Grid item xs={12} sx={{ padding: "0px!important" }}>
                    <CustomOutletSection
                        outlet={data.outlet}
                        handleOpen={handleOutletDialogState}
                        organizationID={data.organization?.id}
                        outletConfiguration={data.outlet?.settings}
                        outletAverageRating={data.outlet.outletAverageRating}
                        outletCountRating={data.outlet.outletCountRating}
                    />
                </Grid>

                <Grid container spacing={2} justifyContent={"center"} justifyItems={"center"} sx={{ width: "100%", margin: "0px 15px 0 0" }}>
                    <Grid item xs={12}>
                        <InputLabel sx={requestTitleStyle()}>{t('request.pageTitle')}</InputLabel>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item sx={{ alignSelf: "center" }}>
                                <PlaceIcon sx={orderIconStyle()} />
                            </Grid>

                            <Grid item xs={8} md={10} sx={{ alignSelf: "center" }}>
                                <Grid container sx={{ marginLeft: "10px", textAlign: "left" }}>
                                    <Grid item xs={12} md={12}>
                                        <CustomTypography variant="body2" text={t('cart.equipmentLabel')} sx={orderHeaderTextStyle()} />
                                    </Grid>
                                    <Grid item xs={"auto"} md={"auto"} sx={{ display: "flex" }}>
                                        <CustomTypography variant="body2" text={equipment} sx={{ ...orderTextStyle(), width: "auto" }} />
                                        <Tooltip title={t("actions.edit")}><EditIcon style={{ color: "lightgrey", fontSize: "20px", marginLeft: "5px" }} onClick={updateEquipmentOpenCallback} /></Tooltip>
                                        <CheckoutEquipmentPopover
                                            anchorEl={equipmentAnchorEl}
                                            open={openEquipment}
                                            handleClose={updateEquipmentCloseCallback}
                                            inputValue={equipment}
                                            handleInputChange={updateEquipmentValue}
                                            handleUpdate={updateEquipmentCallback}
                                            equipmentInvalid={equipmentInvalid}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    {data.types?.includes("ORDER") &&
                        <Grid item xs={6}>
                            <CustomIconButton
                                {...getRequestButton(data.request.type, "order", "ORDER", t('request.allowedRequestTypes.MENU', data, setData))}
                                icon={<ShoppingCartIcon {...getRequestButtonIcon()} sx={requestButtonIcon()} />}
                                onClick={() => formValChangeWithParentElementWithNameAndValue("request", { ...data.request, type: "ORDER", icon: null, label: getTranslationDefaultObject() }, data, setData)}
                            />
                        </Grid>
                    }

                    {data.types?.includes("PAYMENT") &&
                        <Grid item xs={6}>
                            <CustomIconButton
                                {...getRequestButton(data.request.type, "pay", "PAYMENT", t('request.allowedRequestTypes.PAYMENT', data, setData))}
                                icon={<FontAwesomeIcon icon={faMoneyBill} style={requestButtonFontAwesomeIcon()} />}
                                onClick={() => formValChangeWithParentElementWithNameAndValue("request", { ...data.request, type: "PAYMENT", icon: null, label: getTranslationDefaultObject() }, data, setData)}
                            />
                        </Grid>
                    }

                    {data.types?.includes("CLEAN_TABLE") &&
                        <Grid item xs={6}>
                            <CustomIconButton
                                {...getRequestButton(data.request.type, "request", "CLEAN_TABLE", t('request.allowedRequestTypes.CLEAN_TABLE', data, setData))}
                                icon={<FontAwesomeIcon icon={faSprayCanSparkles} style={requestButtonFontAwesomeIcon()} />}
                                onClick={() => formValChangeWithParentElementWithNameAndValue("request", { ...data.request, type: "CLEAN_TABLE", icon: null, label: getTranslationDefaultObject() }, data, setData)}
                            />
                        </Grid>
                    }

                    {(data.types?.includes("CUSTOM") && data.requestExtraCustomButtons?.length > 0) &&
                        <>
                            {data.requestExtraCustomButtons?.map((requestExtraCustomButton) => (
                                <Grid item xs={6}>
                                    <CustomIconButton
                                        {...getRequestButton(data.request.label[getLanguageFromURL()], requestExtraCustomButton?.label[getLanguageFromURL()], requestExtraCustomButton?.label[getLanguageFromURL()], requestExtraCustomButton?.label[getLanguageFromURL()], true)}
                                        icon={<FontAwesomeIcon icon={fas[requestExtraCustomButton.icon]} style={requestButtonFontAwesomeIcon()} />}
                                        onClick={() => formValChangeWithParentElementWithNameAndValue("request", { ...data.request, type: "CUSTOM", icon: requestExtraCustomButton?.icon, label: requestExtraCustomButton?.label }, data, setData)}
                                    />
                                </Grid>
                            ))}
                        </>
                    }

                    {data.types?.includes("OTHER") &&
                        <Grid item xs={6}>
                            <CustomIconButton
                                {...getRequestButton(data.request.type, "other", "OTHER", t('request.allowedRequestTypes.OTHER', data, setData))}
                                icon={<NotesIcon {...getRequestButtonIcon()} sx={requestButtonIcon()} />}
                                onClick={() => formValChangeWithParentElementWithNameAndValue("request", { ...data.request, type: "OTHER", icon: null, label: getTranslationDefaultObject() }, data, setData)}
                            />
                        </Grid>
                    }
                </Grid>

                {data.request?.type === "OTHER" &&
                    <Grid item xs={12}>
                        <Grid container spacing={2} sx={{ margin: "0px 10px" }}>
                            <Grid item xs={12} sm={12} sx={{ textAlign: "-webkit-left" }}>
                                <InputLabel required={false} htmlFor="request" sx={requestTextStyle}>{t('request.request')}</InputLabel>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <CustomTextArea
                                    placeholder={t('request.requestPlaceholder')}
                                    value={data.request.request}
                                    inputProps={{ maxLength: 100 }}
                                    maxLength={100}
                                    onChange={(event) => {
                                        let newValue = event.target.value.slice(0, 100);
                                        formValChangeWithParentElementWithNameAndValue("request.request", newValue, data, setData);
                                    }}
                                    variant="outlined"
                                    sx={{ borderColor: "lightgrey" }}
                                    endDecorator={
                                        <Box sx={requestTextareaBoxStyle}>
                                            <Divider flexItem={true} />
                                            <Typography level="span" sx={{ ml: 'auto', color: data.request.request.length === 100 ? "red" : "initial" }}>
                                                {`${data.request.request.length} ${data.request.request.length === 1 ? t('request.requestCharLimit') : t('request.requestCharsLimit')} /${requestTextFieldLimit}`}
                                            </Typography>
                                        </Box>
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                }

                <Grid item xs={12} md={12}>
                    <CustomIconButton
                        variant="outlined"
                        onClick={() => navigate(`/${getLanguageFromURL()}/${outletURL}?e=${equipment}`)}
                        label={t('order.success.back')}
                        sx={{ textTransform: "none", fontSize: "12px", marginTop: "20px" }}
                    />
                </Grid>

                <Fab
                    aria-label="add"
                    variant="extended"
                    sx={requestFabButtonStyle()}
                    onClick={createRequest}
                    disabled={disableSendButton}
                >
                    <SendIcon sx={{ mr: 1 }} />
                    {t('request.btns.send')}
                </Fab>

                {dialog.isOpen &&
                    <CustomDialogBasedOnScreenChildren
                        isOpen={dialog.isOpen}
                        id="dialog"
                        title={t(`request.popup.${dialog.type}`)}
                        actionLabel={dialog.type === "info" ? t("request.btns.Send") : ""}
                        cancelLabel={t("request.btns.ok")}
                        message={dialog.message}
                        action={dialog.type === "info" ? createRequest : undefined}
                        handleOpen={() => handleDialogState(true)}
                        handleClose={() => handleDialogState(false, "", "")}
                        actionColor="#e91e1e"
                        counter={counter}
                        children={getDialogTypeAnimation(dialog.type)}
                    />
                }

                {outletDialog.isOpen &&
                    <CustomDialogBasedOnScreenChildren
                        isOpen={outletDialog.isOpen}
                        id="outlet-details"
                        handleOpen={() => handleOutletDialogState(true)}
                        handleClose={() => handleOutletDialogState(false, "", "")}
                        actionColor="#e91e1e"
                        children={
                            <CustomOutletDetailsCard
                                handleReviewsClick={() => navigate({
                                    pathname: "feedback",
                                    search: createSearchParams({
                                        e: equipmentLabel
                                    }).toString()
                                })}
                                outlet={data.outlet}
                                organizationID={data.organization.id}
                                outletID={data.outlet.id}
                                outletURL={outletURL}
                                showLanguage={false}
                            />
                        }
                    />
                }
            </CustomGrid>
        );
}

export default RequestComponent;